<template>
  <div class="header">
    <div style="position: absolute;left:0">
      <router-link to="/">
        <el-image :src="require('../assets/App/navlogo.png')" style="margin: 12px 0 12px 50px"/>
      </router-link>
    </div>
    <div style="display: flex;justify-content: center;">
      <el-menu mode="horizontal"
               router
               :default-active="activeIndex"
               background-color="rgb(60,60,60)"
               text-color="#fff"
               active-text-color="rgb(80,155,90)"
               style="border-bottom:none;height: 60px">
        <el-menu-item>
          <router-link to="/" target="_blank">
            <div style="height: 100%;">首页</div>
          </router-link>
        </el-menu-item>
        <el-menu-item index="/game">全部</el-menu-item>
        <el-menu-item>
          <router-link to="/band" target="_blank">
            <div style="height: 100%;">体感外设</div>
          </router-link>
        </el-menu-item>
        <el-menu-item>
          <router-link to="/news" target="_blank">
            <div style="height: 100%">最新资讯</div>
          </router-link>
        </el-menu-item>
        <!--                <el-menu-item>-->
        <!--                  <router-link to="/app" target="_blank"><div style="height: 100%">双动APP</div></router-link>-->
        <!--                </el-menu-item>-->
        <el-menu-item>
          <el-dropdown trigger="click">
            <span class="el-dropdown-link" style="color: #fff;height: 100%;display:inline-block;">APP下载<i
                class="el-icon-arrow-down el-icon--right" style="color: #fff;margin-top: -8px"></i></span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <router-link to="/app" target="_blank">
                  <div style="height: 100%;color: #000">双动APP</div>
                </router-link>
              </el-dropdown-item>
              <el-dropdown-item>
                <router-link to="/hi" target="_blank">
                  <div style="height: 100%;color: #000">嗨科普</div>
                </router-link>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-menu-item>
        <el-menu-item index="/support">服务与支持</el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: "GameHeader",
  props: {
    activeIndex: {
      type: String
    }
  }
}
</script>

<style scoped>
.header {
  background: rgb(60, 60, 60);
  text-align: center;
  line-height: 60px;
  height: 60px;
  width: 100%;
  position: fixed;
  z-index: 6;
  top: 0;
  min-width: 960px;
  box-shadow: 0 0 3px #404040;
  /*padding-right: 50px;*/
  /*padding-left: 50px;*/
}

.router-link-active {
  text-decoration: none;
}

a {
  text-decoration: none;
}

>>> .el-menu-item * {
  vertical-align: inherit;
}
</style>