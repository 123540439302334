<template>
  <div class="all">
    <GameHeader active-index="/game"></GameHeader>
    <div class="main">
      <swiper :options="swiperOption" class="swiper">
        <swiper-slide>
          <router-link to>
            <img src="../../assets/Game/高尔夫之旅海报.jpg"
                 style="height: 50vw;width: 100vw;min-height: 480px;min-width: 960px"/>
          </router-link>
        </swiper-slide>

<!--        <swiper-slide>-->
<!--          <router-link to>-->
<!--            <img src="../../assets/Game/热血网球海报.jpg"-->
<!--                 style="height: 50vw;width: 100vw;min-height: 480px;min-width: 960px"/>-->
<!--          </router-link>-->
<!--        </swiper-slide>-->
        <div slot="pagination" class="swiper-pagination"></div>
      </swiper>

    </div>

    <div v-if="0">
      <div class="card">
        <div @mouseover="videoPlayback()" @mouseout="videoStopped()">
          <img id="img" src="../../assets/Game/高尔夫封面.jpg"/>
          <video preload="none" id="video" loop muted>
            <source src="https://act-web.toodo.com.cn/official-website/video/game1.mp4" type="video/mp4">
          </video>
        </div>
        <div class="details">
          <h2>高尔夫球</h2>
          <div class="down">
            <div class="text">
              <p>类型：健身互动</p>
              <p>大小：80M</p>
            </div>
            <div class="text">
              <p>操控设备：体感手环</p>
              <p>下载方式：APP下载</p>
            </div>
            <div class="link">
              <!--                          <router-link to="/game2">-->
              <el-button class="btn" disabled>敬请期待&#xA0;→
              </el-button>
              <!--                          </router-link>-->
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div @mouseover="videoPlayback2()" @mouseout="videoStopped2()">
          <img id="img2" src="../../assets/Game/网球封面.jpg"/>
          <video preload="none" id="video2" loop muted>
            <source src="https://act-web.toodo.com.cn/official-website/video/game2.mp4" type="video/mp4">
          </video>
        </div>
        <div class="details">
          <h2>体感网球</h2>
          <div class="down">
            <div class="text">
              <p>类型：健身互动</p>
              <p>大小：67M</p>
            </div>
            <div class="text">
              <p>操控设备：体感手环</p>
              <p>下载方式：APP下载</p>
            </div>
            <div class="link">
              <!--              <router-link to="/game3">-->
              <el-button class="btn" disabled>敬请期待&#xA0;→
              </el-button>
              <!--              </router-link>-->
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <div @mouseover="videoPlayback3()" @mouseout="videoStopped3()">
          <img id="img3" src="../../assets/Game/羽毛球封面.jpg"/>
          <video preload="none" id="video3" loop muted>
            <source src="https://act-web.toodo.com.cn/official-website/video/game3.mp4" type="video/mp4">
          </video>
        </div>
        <div class="details">
          <h2>体感羽毛球</h2>
          <div class="down">
            <div class="text">
              <p>类型：健身互动</p>
              <p>大小：78M</p>
            </div>
            <div class="text">
              <p>操控设备：体感手环</p>
              <p>下载方式：APP下载</p>
            </div>
            <div class="link">
              <!--              <router-link to="/game4">-->
              <el-button class="btn" disabled>敬请期待&#xA0;→</el-button>
              <!--              </router-link>-->
            </div>
          </div>

        </div>
      </div>
    </div>



    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../../components/Footer";
import GameHeader from "../../components/GameHeader";

export default {
  name: "Game",
  data() {
    return {
      swiperOption: {
        loop: true,
        //键盘
        keyboard: {
          enabled: true,
        },
        speed: 1500,
        centeredSlides: true,
        autoplay: {
          autoplay: true,
          delay: 2000,
          disableOnInteraction: false
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
      },
    }
  },
  props: {
    activeIndex: {
      type: String
    }
  },
  methods: {
    videoPlayback() {
      let video = document.getElementById('video');
      let img = document.getElementById('img');
      img.style.opacity = "0";
      video.setAttribute("autoplay", "autoplay")
      video.play();
    },
    videoStopped() {
      let img = document.getElementById('img');
      let video = document.getElementById('video');
      img.style.opacity = "1";
      video.pause();
    },

    videoPlayback2() {
      let video2 = document.getElementById('video2');
      let img2 = document.getElementById('img2');
      img2.style.opacity = "0";
      video2.setAttribute("autoplay", "autoplay")
      video2.play();
    },

    videoStopped2() {
      let img2 = document.getElementById('img2');
      let video2 = document.getElementById('video2');
      img2.style.opacity = "1";
      video2.pause();
    },

    videoPlayback3() {
      let video3 = document.getElementById('video3');
      let img3 = document.getElementById('img3');
      img3.style.opacity = "0";
      video3.setAttribute("autoplay", "autoplay")
      video3.play();
    },

    videoStopped3() {
      let img3 = document.getElementById('img3');
      let video3 = document.getElementById('video3');
      img3.style.opacity = "1";
      video3.pause();
    },
  },
  components: {
    GameHeader, Footer
  }
}
</script>

<style scoped>
.all {
  min-width: 960px;
}

.main {
  margin: 60px 0 0 0;
}

.card {
  margin: 50px 15%;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  position: relative;
}

#img, #img2, #img3 {
  width: 100%;
  position: absolute;
  z-index: 4;
  display: block;
  transition-duration: 1s;
}

#img:hover, #img2:hover, #img3:hover {
  opacity: 0;
  /*display: none;*/
}

#video, #video2, #video3 {
  display: block;
  width: 100%;
}

.details {
  background: #f6f6f6;
  width: 100%;
  padding: 10px 5%;
  text-align: left
}

.down {
  border-top: 1px solid #e5e5e5;
  width: 100%;
  position: relative;
  height: 100px;
  margin: 10px 0 0 0;
  color: #6c6c6c;
}

.text {
  width: 25%;
  float: left;
}

.text p {
  padding: 10px 0;
}

.link {
  float: right;
  width: 50%;
}

.btn {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%)
}
</style>